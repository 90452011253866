export default {
  challenges: {
    categories: {
      whoInTheGroup: {
        name: "Who of the group?",
        caption: "Who of the group?",
        instructions:
          "On three everybody points to the person they think is the one. The one with the most votes wins and distributes one sip per vote.",
      },
      truthOrLie: {
        name: "Truth or Lie?",
        caption: "Truth or Lie?",
        instructions:
          "Tell a true story OR lie. Afterwards on three everyone shows a thumbs up if they believe the story to be true " +
          "or a thumbs down if they think it's a lie. If you got fooled drink.",
      },
      task: {
        name: "Dare",
        caption: "Dare",
      },
      rule: {
        name: "Rule",
        caption: "Rule",
      },
      game: {
        name: "Let's play a game",
        caption: "Let's play a game",
      },
      tournament: {
        name: "Tournament",
        caption: "Tournament",
      },
      majorityWins: {
        name: "Majority wins",
        caption: "Majority wins",
      },
      neverHaveIEver: {
        name: "Never have I ever...",
        caption: "Never have I ever...",
        instructions: "Who ever has done it already drinks one sip.",
      },
      unknown: {
        name: "",
        caption: "",
        instructions: "",
      },
    },
  },
  game: {
    selection: {
      title: "What will be next?",
    },
  },
};
