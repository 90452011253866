import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/About.vue"),
  },
  {
    path: "/editor",
    name: "Editor",
    component: () =>
      import(/* webpackChunkName: "editor" */ "../views/editor/Overview.vue"),
  },
  {
    path: "/packs",
    name: "PackSelection",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/PackSelection.vue"),
  },
  {
    path: "/code/",
    redirect: { name: "Settings" },
  },
  {
    path: "/settings/",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/Settings.vue"),
  },
  {
    path: "/code/:code",
    name: "CodeActivation",
    redirect: (to) => {
      const code = to.params.code;
      if (
        !store.state.packCodes.includes(code) &&
        store.state.packs.some((p) => p.code == code)
      ) {
        store.state.packCodes.push(code);
      }
      return { name: "PackSelection" };
    },
  },
  {
    path: "/game",
    name: "Game",
    component: () => import(/* webpackChunkName: "main" */ "../views/Game.vue"),
  },
  {
    path: "/players",
    name: "ChangePlayers",
    component: () =>
      import(/* webpackChunkName: "main" */ "../views/ChangePlayers.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.afterEach((to, from) => {
  store.save();
});

export default router;
