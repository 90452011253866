import { Player } from "@/store/models/player";
import { Pack } from "@/store/models/pack";
import { PackTemplate } from "@/store/models/packTemplate";
import { Type } from "class-transformer";

export class Game {
  public players: Player[] | null = null;
  public settings: { length: number; hideBottleSpin: boolean } = {
    length: 1000,
    hideBottleSpin: false,
  };

  @Type(() => Pack)
  currentPack: Pack | null = null;

  public get playersReady(): boolean {
    return (
      !!this.players &&
      this.players.filter((p) => p.name.trim().length > 0).length > 1
    );
  }

  selectPack(pack: PackTemplate): Pack {
    this.currentPack = new Pack(pack, this.settings);
    return this.currentPack;
  }
}
