import { ChallengeTemplate } from "@/store/models/challengeTemplate";
import { PackTemplate } from "@/store/models/packTemplate";
import { Challenge } from "@/store/models/challenge";
import { Type } from "class-transformer";

export class Pack {
  @Type(() => Challenge)
  protected challengeList: Challenge[] = [];
  protected currentChallengeIndex = 0;
  @Type(() => PackTemplate)
  public packTemplate: PackTemplate;

  constructor(packTemplate: PackTemplate, options: { length: number }) {
    this.packTemplate = packTemplate;
    if (!this.packTemplate) {
      return;
    }
    this.generateChallengeList(options.length); //TODO remember old challenges
  }

  generateChallengeList(length = 20): void {
    const challenges = this.packTemplate.challenges;
    length = Math.min(length, challenges.length);
    //sanity check if challenges.length = 0
    if (challenges.length === 0) {
      return;
    }

    const challengeFilters = {
      notPastChallenge: (c: ChallengeTemplate) =>
        !this.challengeList.some((e) => c.id === e.template.id),
      notDuplicateCategoryToLast: (i) => {
        return (c: ChallengeTemplate) => {
          for (let n = 1; n <= i; n++) {
            if (
              this.challengeList[this.challengeList.length - i] &&
              c.category ===
                this.challengeList[this.challengeList.length - i]?.template
                  .category
            )
              return false;
          }
          return true;
        };
      },
      intensityBetween: (min, max) => {
        return (c: ChallengeTemplate) => {
          return c.intensity <= min && c.intensity >= max;
        };
      },
    };

    for (let i = 0; i < length && challenges.length > 0; i++) {
      let potentialChallenges: ChallengeTemplate[] = [];
      const lastChallenge = this.challengeList[this.challengeList.length - 1];
      const filters: Array<(c: ChallengeTemplate) => boolean> = [
        challengeFilters.notPastChallenge,
        challengeFilters.notDuplicateCategoryToLast(1),
        challengeFilters.notDuplicateCategoryToLast(2),
        challengeFilters.intensityBetween(
          2 + Math.floor(i / (length / 10)),
          5 + Math.floor(i / (length / 10))
        ),
        challengeFilters.intensityBetween(
          1 + Math.floor(i / (length / 10)),
          3 + Math.floor(i / (length / 10))
        ),
      ];

      do {
        potentialChallenges = challenges.filter((c) =>
          filters.every((f) => f(c))
        );
      } while (potentialChallenges.length === 0 && filters.pop());

      const nextChallenge =
        potentialChallenges[
          Math.floor(Math.random() * potentialChallenges.length)
        ];
      console.log(
        nextChallenge.category,
        nextChallenge.primaryTextTemplate.slice(0, 15),
        filters.length
      );

      this.challengeList.push(new Challenge(nextChallenge));
    }
  }

  getFutureChallenges(): Challenge[] {
    return this.challengeList.slice(this.currentChallengeIndex);
  }

  getCurrentChallenge(): Challenge | null {
    return this.challengeList[this.currentChallengeIndex] || null;
  }

  next(): Challenge | null {
    const currentChallengeEffects = this.getCurrentChallenge()!.complete();
    if (currentChallengeEffects.reschedule) {
      this.challengeList.splice(
        this.currentChallengeIndex + currentChallengeEffects.reschedule,
        0,
        this.getCurrentChallenge()!
      );
    }
    this.currentChallengeIndex++;
    return this.getCurrentChallenge();
  }
}
