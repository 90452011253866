<template>
  <v-container
    class="force-mobile"
    :class="{ landscape: landscape }"
    v-resize="onResize"
    v-bind:style="{ transform: transform, 'transform-origin': transformOrigin }"
  >
    <!--    <div style="width: 300px; height: 100px; background: green; position: relative">
          <div style="height: 100px; width: 50px; background: blue; transform-origin: left top;  position: absolute" :style="{transform: transform2}"></div>
        </div>-->
    <top-bar></top-bar>
    <div class="pa-2 fill-height">
      <slot></slot>
    </div>
  </v-container>
</template>

<script>
import TopBar from "@/components/TopBar";
export default {
  name: "MobileView",
  components: { TopBar },
  props: {
    landscape: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  computed: {
    transform2() {
      let maxScaleWidth = 1;
      let maxScaleHeight = 1;
      let translationX = 1;
      let translationY = 1;

      maxScaleWidth = 300 / 100;
      maxScaleHeight = 100 / 50;

      const scale = Math.min(maxScaleHeight, maxScaleWidth);

      translationY = Math.round((300 - 100 * scale) / 2);
      translationX = Math.round((100 - 50 * scale) / 2);

      return `translate(${translationY}px, ${
        100 - translationX
      }px) scale(${scale},${scale}) rotate(-90deg)`;
    },
    transform() {
      let maxScaleWidth = 1;
      let maxScaleHeight = 1;
      let translationX = 1;
      let translationY = 1;

      if (this.isPortraitOrientation) {
        maxScaleWidth = this.windowSize.y / 800;
        maxScaleHeight = this.windowSize.x / 400;
      } else {
        maxScaleWidth = this.windowSize.x / 800;
        maxScaleHeight = this.windowSize.y / 400;
      }

      const scale = Math.min(maxScaleHeight, maxScaleWidth);

      if (this.isPortraitOrientation) {
        translationX = Math.round((this.windowSize.x - 400 * scale) / 2);
        translationY = Math.round((this.windowSize.y - 800 * scale) / 2);
      } else {
        translationX = Math.round((this.windowSize.y - 400 * scale) / 2);
        translationY = Math.round((this.windowSize.x - 800 * scale) / 2);
      }

      if (!this.isRotated) {
        if (this.landscape) {
          return `translate(${translationY}px, ${translationX}px) scale(${scale},${scale}) `;
        }
        return `translate(${translationX}px, ${translationY}px) scale(${scale},${scale}) `;
      }

      if (this.landscape) {
        return `translate(${translationX}px, ${
          this.windowSize.y - translationY
        }px) scale(${scale},${scale}) rotate(-90deg)`;
      }
      return `translate(${translationY}px, ${
        this.windowSize.y - translationX
      }px) scale(${scale},${scale}) rotate(-90deg)`;
    },
    transformOrigin() {
      return this.isRotated ? "left top" : "left top";
    },
    isRotated() {
      return this.isPortraitOrientation === this.landscape;
    },
    isPortraitOrientation() {
      return this.windowSize.y > this.windowSize.x;
    },
  },
  data: () => ({
    windowSize: {
      x: 0,
      y: 0,
    },
  }),
  mounted() {
    this.onResize();
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style scoped>
.force-mobile {
  position: absolute;
  width: 400px;
  height: 800px;
  overflow: auto;
}

.landscape {
  width: 800px;
  height: 400px;
}

/* Portrait */
@media screen and (orientation: landscape) {
  /* .force-mobile {
     width: 800px;
     height: 400px;
   }*/
}
</style>
