import { Player } from "@/store/models/player";
import { ChallengeTemplate } from "@/store/models/challengeTemplate";
import { Type } from "class-transformer";

export class Challenge {
  public initialized = false;
  protected rescheduled = false;
  protected chickendOut = false;
  protected primaryText: string;
  protected secondaryText: string;
  protected punishmentText: string;
  @Type(() => ChallengeTemplate)
  public template: ChallengeTemplate;

  @Type(() => Player)
  public players: Player[] | undefined;

  get isPlayerSpecific(): boolean {
    if (this.rescheduled) {
      return this.secondaryText.includes("$1");
    } else {
      return this.primaryText.includes("$1");
    }
  }

  get canChickenOut(): boolean {
    console.log("asd");
    return (
      this.template.punishmentTextTemplate.trim().length > 0 &&
      !this.chickendOut &&
      !this.rescheduled
    );
  }

  get isChickendOut(): boolean {
    return this.chickendOut;
  }

  constructor(template: ChallengeTemplate = new ChallengeTemplate()) {
    this.template = template;
    this.primaryText = this._prepareText(template.primaryTextTemplate);
    this.secondaryText = this._prepareText(template.secondaryTextTemplate);
    this.punishmentText = this._prepareText(template.punishmentTextTemplate);
  }

  _prepareText(textTemplate: string): string {
    return textTemplate.replace(
      /\[\s*(\d+)\s*[,-]\s*(\d+)\s*\]/g,
      (substring, p1, p2) => {
        const [min, max] = [Number(p1), Number(p2)];
        // replace random Numbers
        return (min + Math.floor(Math.random() * (max - min))).toString();
      }
    );
  }

  init(players: Player[]): void {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    this.players = players.slice();
  }

  getText(): string {
    if (!this.players) {
      throw new Error("Challenge not initialized! Players missing");
    }

    if (this.rescheduled) {
      return this.secondaryText.replace(/\$(\d+)/g, (substring, p1) => {
        return this.players![Number(p1) - 1].name;
      });
    }

    if (this.chickendOut) {
      return this.punishmentText.replace(/\$(\d+)/g, (substring, p1) => {
        return this.players![Number(p1) - 1].name;
      });
    }

    return this.primaryText.replace(/\$(\d+)/g, (substring, p1) => {
      return this.players![Number(p1) - 1].name;
    });
  }

  chickenOut() {
    this.chickendOut = true;
  }

  /**
   * If the challenge ist completed it returns eventual effects on the games
   */
  complete(): { reschedule: number | false } {
    if (!this.rescheduled && !this.chickendOut) {
      if (this.template.category === "rule" && !this.chickendOut) {
        this.rescheduled = true;
        return {
          reschedule: 4 + Math.floor(Math.random() * 8),
        };
      }
    }
    return {
      reschedule: false,
    };
  }
}
