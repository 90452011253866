
import Vue from "vue";

declare global {
  interface Navigator {
    wakeLock?: any;
  }
}

export default Vue.extend({
  name: "App",

  data() {
    return { store: this.$store };
  },
  mounted() {
    setInterval(() => {
      navigator.wakeLock?.request("screen").catch((ex) => console.error(ex));
    }, 3000);
  },
});
