import { ChallengeTemplate } from "@/store/models/challengeTemplate";
import * as uuid from "uuid";
import { Challenge } from "@/store/models/challenge";
import { Type } from "class-transformer";

export class PackTemplate {
  public id: string = uuid.v4();
  public color = "#E0C963";
  public createdAt: Date = new Date();
  public code: string | null = null;
  public name = "";
  public description = "";
  public image: string | undefined;

  @Type(() => ChallengeTemplate)
  public challenges: ChallengeTemplate[] = [];

  removeChallenge(id: string): void {
    const index = this.challenges.findIndex((c) => c.id === id);
    if (index !== -1) {
      this.challenges.splice(index, 1);
    }
  }

  getChallenge(c: ChallengeTemplate): ChallengeTemplate | undefined {
    return this.challenges.find((i) => i.id === c.id);
  }

  constructor(data?: {
    color: string;
    name: string;
    description: string;
    image: string;
    challenges: ChallengeTemplate[];
    code?: string;
  }) {
    if (data) {
      this.color = data.color;
      this.name = data.name;
      this.description = data.description;
      this.code = data.code || null;
      this.image = data.image;
      this.challenges = data.challenges;
    }
  }
}
