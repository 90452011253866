import _Vue, { PluginObject } from "vue";
import { ChallengeTemplate } from "@/store/models/challengeTemplate";
import { Pack } from "@/store/models/pack";
import { Game } from "@/store/models/game";
import {
  classToPlain,
  deserialize,
  deserializeArray,
  plainToClass,
  serialize,
} from "class-transformer";
import { Challenge } from "@/store/models/challenge";
import { PackTemplate } from "@/store/models/packTemplate";
import { csv } from "@/store/baseChallenges.csv";

const defaultPacks: PackTemplate[] = [
  new PackTemplate({
    name: "Let's drink",
    color: "#E0C963",
    description:
      "The evening is still young, time to loosen\n" +
      "up and drink a little. Get yourself a drink, lean \n" +
      "back and start to spin the bottle. ",
    image: "",
    challenges: [],
  }),
  new PackTemplate({
    name: "Time to get crazy",
    color: "#98F6E7",
    description:
      "Do you feel confident enough so that you're\n" +
      "ready to go a bit further? Be prepared to loose \n" +
      "control and spin around. ",
    image: "",
    challenges: [],
  }),
  new PackTemplate({
    name: "Love is all Around",
    color: "#F385D6",
    description:
      "You've been always interested in getting to know\n" +
      "each other much better? It's time to add some \n" +
      "ice to your drink, it might get hot in here. ",
    image: "",
    challenges: [],
  }),
  new PackTemplate({
    name: "Bride 2 Be",
    color: "#e6c619",
    code: "bride",
    description:
      "Enjoy your last days before it's officially game over! " +
      "It's time to find out the truth about your closest " +
      "friends and reveal some of the brides darkest secrets.",
    image: "",
    challenges: [],
  }),
  new PackTemplate({
    name: "Silvester 2022",
    color: "#59ea64",
    code: "",
    description:
      "Enjoy your last day before the year is officially over! Make your wishes, look back at your greatest `22 fails and reveal your darkest secrets. Happy New Year 2023!",
    image: "",
    challenges: [],
  }),
];

const categories = [
  "whoInTheGroup",
  "truthOrLie",
  "task",
  "rule",
  "tournament",
  "neverHaveIEver",
];

function loadBaseChallenges(packs: PackTemplate[]): PackTemplate[] {
  const lines = csv.split("\n");
  lines.forEach((l) => {
    const data = l.split("\t");
    if (!categories.some((c) => c == data[1]?.trim())) {
      console.log("Skipping (illegal format)" + l);
      return;
    }

    if (data[3].trim().length === 0) {
      return;
    }

    const challengeTemplate = new ChallengeTemplate("Base");
    Object.assign(challengeTemplate, {
      id: data[0],
      category: data[1],
      intensity: Number(data[2]),
      lang: "en",
      primaryTextTemplate: data[3].trim(),
      secondaryTextTemplate: data[5].trim(),
      punishmentTextTemplate: data[4].trim(),
    });
    switch (data[9].trim()) {
      case "Let's Drink":
        packs[0].challenges.push(challengeTemplate);
        break;
      case "Time To Get Crazy":
        packs[1].challenges.push(challengeTemplate);
        break;
      case "XXX":
        packs[2].challenges.push(challengeTemplate);
        break;
      case "Bride to Be":
        packs[3].challenges.push(challengeTemplate);
        break;
      case "Silvester 2022":
        packs[4].challenges.push(challengeTemplate);
        break;
      default:
        console.log("Skipping (illegal pack)" + l);
    }
  });
  return packs;
}

export class Store<T> implements PluginObject<any> {
  public creator = "Christian F.";
  public lastUpdate = new Date();
  public packs = loadBaseChallenges(defaultPacks) as PackTemplate[];
  public packCodes = [];
  public game = new Game();

  public get state(): Store<T> {
    return this;
  }

  save() {
    localStorage.data = JSON.stringify({
      creator: this.creator,
      lastUpdate: serialize(new Date()),
      //packs: serialize(this.packs),
      packCodes: this.packCodes,
      game: serialize(this.game),
    });
    console.log("State saved!", this);
  }

  load() {
    const data = JSON.parse(localStorage.data || null);
    if (!data) {
      return;
    }
    this.creator = data.creator;
    this.lastUpdate = data.lastUpdate
      ? deserialize(Date, data.lastUpdate)
      : new Date();
    this.packCodes = data.packCodes || [];
    //this.packs = deserializeArray(PackTemplate, data.packs);
    this.game =
      data.game && Date.now() - this.lastUpdate.getTime() < 24 * 60 * 60 * 1000
        ? deserialize(Game, data.game)
        : new Game();

    console.log("State loaded", this);
  }

  install(Vue: typeof _Vue, options: any): void {
    Vue.prototype.$store = store;
  }
}

const store = new Store();
store.load();
export default store;
