
import store from "@/store";

export default {
  name: "TopBar",
  computed: {
    game() {
      return store.game;
    },
  },
};
