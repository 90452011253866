import * as uuid from "uuid";

export type ChallengeCategory =
  | "whoInTheGroup"
  | "truthOrLie"
  | "task"
  | "rule"
  | "game"
  | "tournament"
  | "majorityWins"
  | "neverHaveIEver"
  | "unknown";

export const ChallengeCategories: ChallengeCategory[] = [
  "whoInTheGroup",
  "truthOrLie",
  "task",
  "rule",
  "game",
  "tournament",
  "majorityWins",
  "neverHaveIEver",
];

export class ChallengeTemplate {
  public primaryTextTemplate = "";
  public secondaryTextTemplate = "";
  public punishmentTextTemplate = "";
  public id: string;
  public creator = "";
  public category: ChallengeCategory = "unknown";
  public lang: "de" | "en" = "en";
  public createdAt: Date = new Date();
  public intensity = 0;

  constructor(creator = "Anonymous") {
    this.id = uuid.v4();
    this.creator = creator;
  }
}
