export const csv = `
Id\tCategory\tintensity\ttext_en\tpunishment_text_en\ttext2_en\ttext_de\ttext2_de\tpunishment_text_de\tpack\t\t\t
3\twhoInTheGroup\t1\tWho of the group had the best childhood?\t\t\tWer der Gruppe hatte die beste Kindheit?\t\t\tLet's Drink\t\t\t
6\twhoInTheGroup\t1\tWho of the group can drink the most?\t\t\tWer der Gruppe kann am meisten trinken?\t\t\tLet's Drink\t\t\t
11\twhoInTheGroup\t1\tWho of the group is the best leader?\t\t\tWer der Gruppe ist der beste Anführer?\t\t\tLet's Drink\t\t\t
21\twhoInTheGroup\t1\tWho of the group would survive on the least money?\t\t\tWer der Gruppe würde mit dem geringsten Geld überleben?\t\t\tLet's Drink\t\t\t
36\twhoInTheGroup\t1\tWho of the group is most open minded? \t\t\tWer der Gruppe ist am offensten?\t\t\tLet's Drink\t\t\t
46\ttruthOrLie\t1\t$1, tell us about your craziest travel experience.\t\t\t$1, erzählen Sie uns von Ihrem verrücktesten Reiseerlebnis.\t\t\tLet's Drink\t\t\t
63\ttruthOrLie\t1\t$1, tell us about your most useless present.\t\t\t$1, erzählen Sie uns von Ihrem nutzlossten Geschenk.\t\t\tLet's Drink\t\t\t
85\ttask\t1\t$1 drink [2,6] sips. We don't want you to die of thirst.\t\t\t$1 Drink [2,6] Schluck. Wir wollen nicht, dass du mit Durst sterbe.\t\t\tLet's Drink\t\t\t
87\ttask\t1\tEveryone drinks [2,5] sips\t\t\tJeder trinkt [2,5] Schlucke\t\t\tLet's Drink\t\t\t
103\ttask\t1\t$1 give someone a nice hug. \t\t\t$1 Gib jemandem eine schöne Umarmung.\t\t\tLet's Drink\t\t\t
129\tneverHaveIEver\t1\tNever have I ever pretended to be sick to skip work\t\t\tIch habe nie vorgeben, krank zu sein, um die Arbeit zu überspringen\t\t\tLet's Drink\t\t\t
134\tneverHaveIEver\t1\tNever have I ever had a car accident\t\t\tIch hatte noch nie einen Autounfall\t\t\tLet's Drink\t\t\t
135\tneverHaveIEver\t1\tNever have I ever gotten a ticket\t\t\tIch habe noch nie ein Ticket bekommen\t\t\tLet's Drink\t\t\t
138\tneverHaveIEver\t1\tNever have I ever cheated at an exam\t\t\tIch habe nie bei einer Prüfung betrogen\t\t\tLet's Drink\t\t\t
152\trule\t1\tEvery sentence needs to end with "dude". Who forgets drinks.\t\tYou can stop finishing your sentences with "dude"\tJeder Satz muss mit "Typ" enden. Wer vergisst Getränke.\tSie können aufhören, Ihre Sätze mit "Dude" zu beenden\t\tLet's Drink\t\t\t
16\twhoInTheGroup\t1\tWho of the group spends the most time on their phone?\t\t\tWer der Gruppe verbringt die meiste Zeit mit ihrem Telefon?\t\t\tTime To Get Crazy\t\t\t
86\ttask\t1\t$1 you little drunk, drink [3,5] sips for your mama.\t\t\t$1 Sie wenig betrunken, trinken [3,5] Schlucke für Ihre Mama.\t\t\tTime To Get Crazy\t\t\t
120\tneverHaveIEver\t1\tNever have I ever eaten my own bugger\t\t\tIch habe noch nie meinen eigenen Mist gegessen\t\t\tTime To Get Crazy\t\t\t
130\tneverHaveIEver\t1\tNever have I ever been high at work\t\t\tIch war noch nie hoch bei der Arbeit\t\t\tTime To Get Crazy\t\t\t
177\ttournament\t2\tGoing around everyone names a car brand. Who can't or repeats something drinks [2,6] sips. $1 starts.\t\t\tWenn Sie alle herumgehen, nennt man eine Automarke. Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 beginnt.\t\t\tLet's Drink\t\t\t
179\ttournament\t2\tGoing around everyone names a dog breed. Who can't or repeats something drinks [2,6] sips. $1 starts.\t\t\tWenn Sie alle herumgehen, nennt man eine Hunderasse. Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 beginnt.\t\t\tLet's Drink\t\t\t
187\ttournament\t2\t$1 vs. $2: Staring duel. Who laughs first loses and drinks [3,6] sips, winner gives [2,5] sips.\t\t\t$1 vs. $2: Starring Duell. Wer lacht zum ersten Mal verliert und trinkt [3,6] Schlucken? Der Gewinner gibt [2,5] Schlucken.\t\t\tLet's Drink\t\t\t
189\ttournament\t2\tRock-Paper-Scissor: Everyone vs. $1. Who loses drinks [2,3] sips. \t\t\tRock-Papier-Scissor: Jeder gegen 1 $. Wer verliert Getränke [2,3] Schlucken.\t\t\tLet's Drink\t\t\t
208\ttask\t1\t$1 give [5,8] sips.\t\t\t$1 Geben Sie [5,8] Schlucken.\t\t\tTime To Get Crazy\t\t\t
209\ttask\t1\t$1 drink [2,6] sips\t\t\t$1 Drink [2,6] Schlucke\t\t\tTime To Get Crazy\t\t\t
20\twhoInTheGroup\t1\tWho of the group is the most passionate?\t\t\tWer der Gruppe ist am leidenschaftlichsten?\t\t\tXXX\t\t\t
95\ttask\t1\t$1 pet $2's cheeks.\tBoth of you drink [2,5] sips.\t\t$1 Pet $2's Wangen.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
147\tneverHaveIEver\t1\tNever have I ever dreamed about having sex with someone in this group\t\t\tIch habe noch nie davon geträumt, Sex mit jemandem in dieser Gruppe zu haben\t\t\tXXX\t\t\t
150\trule\t1\tEvery sentence needs to end with "darling". Who forgets drinks.\t\tYou can stop finishing your sentences with "darling"\tJeder Satz muss mit "Liebling" enden. Wer vergisst Getränke.\tSie können aufhören, Ihre Sätze mit "Liebling" zu beenden\t\tXXX\t\t\t
178\ttournament\t3\tGoing around everyone names a computer game. Who can't or repeats something drinks [2,6] sips. $1 starts.\t\t\tWenn Sie alle herumgehen, nennt man ein Computerspiel. Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 beginnt.\t\t\tLet's Drink\t\t\t
180\ttournament\t3\tGoing around everyone names a poet. Who can't or repeats something drinks [2,6] sips. $1 starts.\t\t\tJeder zu gehen, nennt einen Dichter. Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 beginnt.\t\t\tLet's Drink\t\t\t
203\ttask\t1\t$1 describe in detail, how you would give someone a blowjob. \t$1 you prude! Drink [3, 6] sips to relax.\t\t$1 Beschreiben Sie im Detail, wie Sie jemandem einen Blowjob geben würden.\t\t$1 du prude! Trinken [3, 6] schlucke, um sich zu entspannen.\tXXX\t\t\t
1\twhoInTheGroup\t2\tWho of the group had the longest relationship?\t\t\tWer der Gruppe hatte die längste Beziehung?\t\t\tLet's Drink\t\t\t
5\twhoInTheGroup\t2\tWho of the group eats the most?\t\t\tWer von der Gruppe isst am meisten?\t\t\tLet's Drink\t\t\t
10\twhoInTheGroup\t2\tWho of the group will make the most money in 20 years from now?\t\t\tWer der Gruppe wird in 20 Jahren das meiste Geld verdienen?\t\t\tLet's Drink\t\t\t
15\twhoInTheGroup\t2\tWho of the group read the most books?\t\t\tWer der Gruppe hat die meisten Bücher gelesen?\t\t\tLet's Drink\t\t\t
19\twhoInTheGroup\t2\tWho of the group would simply nail every job interview?\t\t\tWer der Gruppe würde einfach jedes Vorstellungsgespräch festnageln?\t\t\tLet's Drink\t\t\t
24\twhoInTheGroup\t2\tWho of the group tells the funniest jokes?\t\t\tWer der Gruppe erzählt die lustigsten Witze?\t\t\tLet's Drink\t\t\t
31\twhoInTheGroup\t2\tWho of the group is the most romantic?\t\t\tWer der Gruppe ist am romantischsten?\t\t\tLet's Drink\t\t\t
35\twhoInTheGroup\t2\tWho of the group will get oldest? \t\t\tWer der Gruppe wird älteste?\t\t\tLet's Drink\t\t\t
45\ttruthOrLie\t2\t$1, tell us about your worst job interview.\t\t\t$1, erzählen Sie uns von Ihrem schlechtesten Vorstellungsgespräch.\t\t\tLet's Drink\t\t\t
124\tneverHaveIEver\t2\tNever have I ever had sex on the first date\t\t\tIch hatte nie beim ersten Date Sex\t\t\tLet's Drink\t\t\t
127\tneverHaveIEver\t2\tNever have I ever lied to my boss\t\t\tIch habe noch nie meinen Chef angelogen\t\t\tLet's Drink\t\t\t
133\tneverHaveIEver\t2\tNever have I ever stolen something in a store\t\t\tIch habe noch nie etwas in einem Geschäft gestohlen\t\t\tLet's Drink\t\t\t
148\trule\t2\tFrom now on everyone drinks with their weak hand (right-handers with left and vice versa) Who fails drinks!\t\tYou don't have to keep drinking with your weak hand.\tVon nun an trinkt jeder mit seiner schwachen Hand (Rechtshänder mit links und umgekehrt), die keine Getränke scheitern!\tSie müssen nicht weiter mit Ihrer schwachen Hand trinken.\t\tLet's Drink\t\t\t
154\trule\t2\t$1 you are Medusa... No one is allowed to look into $1's eyes. Who forgets drinks\t\t$1 is cured, you can look at $1's eyes again.\t$1 Sie sind Medusa ... niemand darf sich in die Augen von $1 schauen. Wer vergisst Getränke\t$1 ist geheilt, Sie können sich wieder 1 $1 ansehen.\t\tLet's Drink\t\t\t
156\trule\t2\t"There is no I in team" - No one is allowed to use "I" anymore. Who forgets drinks!\t\tLucky for you egomaniacs, your are allowed to use "I" again\t"Es gibt kein Ich im Team" - niemand darf "I" mehr benutzen. Wer vergisst Getränke!\tGlück für Sie Egomane, Sie dürfen "I" wieder verwenden\t\tLet's Drink\t\t\t
157\trule\t2\tEverybody needs to speak about himself/herself in the third person. Who forgets drinks. \t\tYou may refer to yourself as I again.\tJeder muss in der dritten Person über sich/sich selbst sprechen. Wer vergisst Getränke.\t\t\tLet's Drink\t\t\t
191\ttournament\t3\tBreath holding challenge: Who lasts the longest wins and gives [3,6] sips\t\t\tChallenge für Atemhalte: Wer dauert die längsten Siege und gibt [3,6] Schlucke\t\t\tLet's Drink\t\t\t
190\ttournament\t4\tStacking challenge: Who stacks 5 things on top of each other wins and gives 5 sips\t\t\tStacking Challenge: Wer stapelt 5 Dinge übereinander gewinnt und gibt 5 Schlucke\t\t\tLet's Drink\t\t\t
193\ttournament\t4\tMime challenge: $1 mimes something. Whoever guesses it first gives [2,5] sips.\t\t\tMime Challenge: $1 Mimes etwas. Wer es zuerst vermutet, gibt [2,5] Schlucken.\t\t\tLet's Drink\t\t\t
181\ttournament\t1\tGoing around everyone names a brand of beer. Who can't or repeats something drinks [2,6] sips. $1 starts.\t\t\tWenn Sie alle herumgehen, nennt man eine Biermarke. Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 beginnt.\t\t\tTime To Get Crazy\t\t\t
22\twhoInTheGroup\t2\tWho of the group would survive 1 month without internet?\t\t\tWer der Gruppe würde 1 Monat ohne Internet überleben?\t\t\tTime To Get Crazy\t\t\t
39\twhoInTheGroup\t2\tWho of the group has the scariest nightmares? \t\t\tWer der Gruppe hat die gruseligsten Albträume?\t\t\tTime To Get Crazy\t\t\t
62\ttruthOrLie\t2\t$1, tell us about something you are only doing when nobody is looking? \t\t\t$1, erzählen Sie uns von etwas, das Sie nur tun, wenn niemand schaut?\t\t\tTime To Get Crazy\t\t\t
125\tneverHaveIEver\t2\tNever have I ever faked an orgasm\t\t\tIch habe noch nie einen Orgasmus gefälscht\t\t\tTime To Get Crazy\t\t\t
151\trule\t2\tEvery sentence needs to end with "bloody hell". Who forgets drinks.\t\tYou can stop finishing your sentences with "bloody hell"\tJeder Satz muss mit "blutiger Hölle" enden. Wer vergisst Getränke.\tSie können aufhören, Ihre Sätze mit "blutiger Hölle" zu beenden\t\tTime To Get Crazy\t\t\t
153\trule\t2\tYou are no longer allowed to use names. Who forgets drinks! (Exception: while reading challenges for this game)\t\tYou can start using names again.\tSie dürfen keine Namen mehr verwenden. Wer vergisst Getränke! (Ausnahme: Während des Lesens von Herausforderungen für dieses Spiel)\tSie können wieder Namen verwenden.\t\tTime To Get Crazy\t\t\t
159\trule\t2\tDrinking buddies: Whenever $1 drinks, $2 drinks as well.\t\t$2, you no longer have to drink whenever $1 drinks\tTrinkkumpels: Wann immer 1 Getränke $2, auch 2 Dollar Getränke.\t$2, Sie müssen nicht mehr trinken, wenn 1 $ trinken\t\tTime To Get Crazy\t\t\t
167\trule\t2\tThe sound of silence: No one is allowed to make a sound. Who breaks the silence drinks.\t\tYou can be as loud as you want again.\tDer Klang der Stille: Niemand darf ein Geräusch machen. Wer bricht die Stille trinken.\tSie können so laut sein, wie Sie möchten.\t\tTime To Get Crazy\t\t\t
184\ttournament\t1\tGoing around everyone names a capital city. Who can't or repeats something drinks [2,6] sips. $1 starts.\t\t\tWenn Sie alle herumgehen, nennt man eine Hauptstadt. Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 beginnt.\t\t\tTime To Get Crazy\t\t\t
185\ttournament\t1\tGoing around everyone names something from a certain topic (e.g. car brands). Who can't or repeats something drinks [2,6] sips. $1 decides which topic and starts.\t\t\tWenn Sie alle herumgehen, nennt man etwas aus einem bestimmten Thema (z. B. Automarken). Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 entscheidet welches Thema und beginnt.\t\t\tTime To Get Crazy\t\t\t
13\twhoInTheGroup\t2\tWho of the group masturbates the most?\t\t\tWer der Gruppe masturbiert am meisten?\t\t\tXXX\t\t\t
29\twhoInTheGroup\t2\tWho of the group is the biggest sex addict?\t\t\tWer der Gruppe ist der größte Sexsüchtige?\t\t\tXXX\t\t\t
113\tneverHaveIEver\t2\tNever have I ever masturbated under the shower\t\t\tIch habe nie unter der Dusche masturbiert\t\t\tXXX\t\t\t
114\tneverHaveIEver\t2\tNever have I ever cheated on my partner\t\t\tIch habe nie meinen Partner betrogen\t\t\tXXX\t\t\t
121\tneverHaveIEver\t2\tNever have I ever masturbated at work\t\t\tIch habe nie bei der Arbeit masturbiert\t\t\tXXX\t\t\t
142\tneverHaveIEver\t2\tNever have I ever filmed myself having sex\t\t\tIch habe nie selbst Sex gefilmt\t\t\tXXX\t\t\t
144\tneverHaveIEver\t2\tNever have I ever had sex in public\t\t\tIch hatte noch nie Sex in der Öffentlichkeit\t\t\tXXX\t\t\t
171\trule\t2\t$1 needs to talk in a flirty voice. \t\tEnough flirting, $1 you can talk normal again, if you prefere... \t$1 muss mit flirtender Stimme sprechen.\tGenug Flirten, $1 Sie können wieder normal sprechen, wenn Sie bevorzugen ...\t\tXXX\t\t\t
204\ttask\t2\t$1 describe in detail, how you would go down on a woman.\t$1 you prude! Drink [3, 6] sips to relax.\t\t$1 Beschreiben Sie im Detail, wie Sie eine Frau untergehen würden.\t\t$1 du prude! Trinken [3, 6] schlucke, um sich zu entspannen.\tXXX\t\t\t
206\ttask\t2\tTop-less: Everyone keeps playing top-less (Women can keep their bra on if they want). Who chickens out or gets dressed again drinks [3,8] sips\t\tYou don't have to keep playing top-less, if you don't want to.\tTop-Less: Jeder spielt immer wieder Top-Less (Frauen können ihren BH anhalten, wenn er will). Wer Hühner ausgehen oder sich wieder anziehen, trinkt [3,8] Schluck [3,8].\tSie müssen nicht weiterhin Top-Less spielen, wenn Sie nicht wollen.\t\tXXX\t\t\t
211\ttask\t2\t$1 enter sex into your WhatsApp search and read out the first message.\t\t\t$1 Geben Sie Sex in Ihre WhatsApp -Suche ein und lesen Sie die erste Nachricht vor.\t\t\tXXX\t\t\t
9\twhoInTheGroup\t3\tWho of the group is most concerned about what others think of them?\t\t\tWer der Gruppe ist am meisten besorgt darüber, was andere von ihnen denken?\t\t\tLet's Drink\t\t\t
28\twhoInTheGroup\t3\tWho of the group fights the hardest for a better tomorrow?\t\t\tWer der Gruppe kämpft am schwierigsten für ein besseres Morgen?\t\t\tLet's Drink\t\t\t
52\ttruthOrLie\t3\t$1, tell us about your dream job as a kid.\t\t\t$1, erzählen Sie uns von Ihrem Traumjob als Kind.\t\t\tLet's Drink\t\t\t
102\ttask\t3\t$1 gives $2 a nice long hug.\t\t\t$1 gibt 2 $ eine schöne lange Umarmung.\t\t\tLet's Drink\t\t\t
110\ttask\t3\t$1 hugs the person he/she feels most close to.\t\t\t$1 umarmt die Person, der sie/sie am nahe kommt.\t\t\tLet's Drink\t\t\t
119\tneverHaveIEver\t3\tNever have I ever smoked weed\t\t\tIch habe noch nie Gras geraucht\t\t\tLet's Drink\t\t\t
128\tneverHaveIEver\t3\tNever have I ever skipped out on my debt to someone\t\t\tIch habe noch nie meine Schulden gegenüber jemandem übersprungen\t\t\tLet's Drink\t\t\t
166\trule\t3\tEveryone needs to talk to $1 as if they were a small child.\t\t$1 is all grown up now. No need to treat them as a child anymore.\tJeder muss mit 1 Dollar sprechen, als ob er ein kleines Kind wäre.\t$1 ist jetzt alle erwachsen. Sie müssen sie nicht mehr als Kind behandeln.\t\tLet's Drink\t\t\t
186\ttournament\t1\t$1 vs. $2: Thumbfight. 1 Round. Looser drinks [3,6] sips, winner gives [2,5] sips.\t\t\t$1 vs. $2: Daumenkampf. 1 Runde. Lockerere Getränke [3,6] Schluck, Gewinner gibt [2,5] Schlucken.\t\t\tTime To Get Crazy\t\t\t
173\ttournament\t2\tGoing around everyone sings a line from a love song. Who can't or repeats something drinks [2,6] sips\t\t\tAlle herumgehen, singt eine Zeile von einem Liebeslied. Wer kann etwas nicht trinken oder wiederholt [2,6] Schlucke\t\t\tTime To Get Crazy\t\t\t
194\ttournament\t2\tHumming challenge: $1 hums a song. Whoever guesses it first gives [2,5] sips.\t\t\tSumm Challenge: $1 summt ein Lied. Wer es zuerst vermutet, gibt [2,5] Schlucken.\t\t\tTime To Get Crazy\t\t\t
17\twhoInTheGroup\t3\tWho of the group is the most likely to forget their mothers birthday?\t\t\tWer der Gruppe vergisst am wahrscheinlichsten den Geburtstag der Mutter?\t\t\tTime To Get Crazy\t\t\t
118\tneverHaveIEver\t3\tNever have I ever taken hard drugs\t\t\tIch habe noch nie harte Drogen genommen\t\t\tTime To Get Crazy\t\t\t
131\tneverHaveIEver\t3\tNever have I ever pissed in a bottle\t\t\tIch habe nie in eine Flasche sauer\t\t\tTime To Get Crazy\t\t\t
136\tneverHaveIEver\t3\tNever have I ever driven drunk\t\t\tIch habe nie betrunken gefahren\t\t\tTime To Get Crazy\t\t\t
140\tneverHaveIEver\t3\tNever have I ever puked because of alcohol poisoning\t\t\tIch habe noch nie wegen Alkoholvergiftung gekotzt\t\t\tTime To Get Crazy\t\t\t
143\tneverHaveIEver\t3\tNever have I ever send someone nude pictures of myself\t\t\tNie habe ich nie jemanden nackte Bilder von mir selbst schicke\t\t\tTime To Get Crazy\t\t\t
158\trule\t3\t$1 you are now french, speak with a french accent. If you screw up drink.\t\t$1 you can return to your normal accent.\t$1 Sie sind jetzt Französisch, sprechen Sie mit einem französischen Akzent. Wenn Sie Getränk vermasseln.\t$1 Sie können zu Ihrem normalen Akzent zurückkehren.\t\tTime To Get Crazy\t\t\t
164\trule\t3\tEverytime before you drink, you need to say: "I love you guys". Who forgets can try again.\t\tEveryone can just drink again without saying "I love you guys" beforehand.\tJedes Mal, bevor Sie trinken, müssen Sie sagen: "Ich liebe euch". Wer vergisst, kann es erneut versuchen.\tJeder kann einfach wieder trinken, ohne vorher "Ich liebe euch" zu sagen.\t\tTime To Get Crazy\t\t\t
199\ttask\t3\tMassage-Round: Everyone massages their left neighbor.\t\t\tMassage-Runden: Jeder massiert seinen linken Nachbarn.\t\t\tTime To Get Crazy\t\t\t
210\ttask\t3\t$1 enter porn in the address bar of your browser and show everyone the suggestions.\t\t\t$1 Geben Sie Pornos in die Adressleiste Ihres Browsers ein und zeigen Sie allen die Vorschläge.\t\t\tTime To Get Crazy\t\t\t
26\twhoInTheGroup\t3\tWho of the group is the most loyal?\t\t\tWer der Gruppe ist am loyalsten?\t\t\tXXX\t\t\t
30\twhoInTheGroup\t3\tWho of the group is most open to polyamory? \t\t\tWer der Gruppe ist am offensten für Polyamory?\t\t\tXXX\t\t\t
50\ttruthOrLie\t3\t$1, tell us about your worst date.\t\t\t$1, erzählen Sie uns von Ihrem schlechtesten Datum.\t\t\tXXX\t\t\t
93\ttask\t3\t$1 pet $2's back.\tBoth of you drink [2,5] sips.\t\t$1 Pet $2's Rücken.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
115\tneverHaveIEver\t3\tNever have I ever had sex with [2,4] people on the same day\t\t\tIch hatte noch nie Sex mit [2,4] Menschen am selben Tag\t\t\tXXX\t\t\t
141\tneverHaveIEver\t3\tNever have I ever tried on the underwear of my partner\t\t\tIch habe noch nie die Unterwäsche meines Partners ausprobiert\t\t\tXXX\t\t\t
146\tneverHaveIEver\t3\tNever have I ever had sex in my parents bed\t\t\tIch hatte noch nie Sex im Bett meines Elternbettes\t\t\tXXX\t\t\t
169\trule\t3\t$1 is getting tied up. Someone may need to help out while drinking...\t\tYou can untie $1 now.\t$1 wird gefesselt. Möglicherweise muss jemand beim Trinken helfen ...\tSie können jetzt 1 Dollar entfernen.\t\tXXX\t\t\t
188\ttournament\t5\tPush-Up Challenge. Who is able to do the most without stopping wins and gives [4-12] sips.\t\t\tPush-up-Herausforderung. Wer ist in der Lage, am meisten zu tun, ohne Gewinne zu stoppen, und gibt [4-12] Schluck.\t\t\tTime To Get Crazy\t\t\t
213\ttask\t3\t$1 show everyone an intimate area of your body.\tDon't be ashamed of your body! But if you really don't want to drink [4,6] sips.\t\t$1 Zeigen Sie allen einen intimen Bereich Ihres Körpers.\t\tSchäme dich nicht für deinen Körper! Aber wenn Sie wirklich nicht trinken wollen [4,6] Schluck.\tXXX\t\t\t
40\twhoInTheGroup\t4\tWho of the group is the best liar? \t\t\tWer der Gruppe ist der beste Lügner?\t\t\tLet's Drink\t\t\t
75\ttask\t4\t$1, let $2 select your new phone background.\t$1 for not trusting you drink [3,6] sips.\t\t$1, $2 Wählen Sie Ihren neuen Telefonhintergrund aus.\t\t$1, weil Sie nicht vertrauen, dass Sie trinken [3,6] Schlucken.\tLet's Drink\t\t\t
182\ttournament\t1\tGoing around everyone names a sex toy. Who can't or repeats something drinks [2,6] sips. $1 starts.\t\t\tWenn Sie alle herumgehen, nennt man ein Sexspielzeug. Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 beginnt.\t\t\tXXX\t\t\t
183\ttournament\t1\tGoing around everyone names a sex position. Who can't or repeats something drinks [2,6] sips. $1 starts.\t\t\tWenn Sie alle herumgehen, nennt man eine Sexposition. Wer kann etwas trinken oder wiederholt [2,6] Schluck. $1 beginnt.\t\t\tXXX\t\t\t
2\twhoInTheGroup\t4\tWho of the group is the loudest in bed?\t\t\tWer der Gruppe ist am lautesten im Bett?\t\t\tTime To Get Crazy\t\t\t
7\twhoInTheGroup\t4\tWho of the group is the biggest stoner?\t\t\tWer der Gruppe ist der größte Stoner?\t\t\tTime To Get Crazy\t\t\t
18\twhoInTheGroup\t4\tWho of the group would make the best president?\t\t\tWer der Gruppe würde den besten Präsidenten machen?\t\t\tTime To Get Crazy\t\t\t
23\twhoInTheGroup\t4\tWho of the group is the biggest hypochondriac?\t\t\tWer der Gruppe ist die größte Hypochondrien?\t\t\tTime To Get Crazy\t\t\t
25\twhoInTheGroup\t4\tWho of the group laughs loudest at their own jokes?\t\t\tWer von der Gruppe lacht am lautesten bei ihren eigenen Witzen?\t\t\tTime To Get Crazy\t\t\t
37\twhoInTheGroup\t4\tWho of the group is most likely to forget flashing the toilet? \t\t\tWer der Gruppe ist höchstwahrscheinlich vergessen, die Toilette zu blinken?\t\t\tTime To Get Crazy\t\t\t
43\twhoInTheGroup\t4\tWho of the group is the most likely to get pulled out for a "random" drug search?\t\t\tWer der Gruppe wird am wahrscheinlichsten für eine "zufällige" Drogensuche herausgezogen?\t\t\tTime To Get Crazy\t\t\t
48\ttruthOrLie\t4\t$1 tell us about the whatsapp message you regretted the most in retrospect? \t\t\t$1 Erzählen Sie uns von der WhatsApp -Nachricht, die Sie im Nachhinein am meisten bedauert haben?\t\t\tTime To Get Crazy\t\t\t
53\ttruthOrLie\t4\t$1, tell us about your biggest dream.\t\t\t$1, erzählen Sie uns von Ihrem größten Traum.\t\t\tTime To Get Crazy\t\t\t
68\ttask\t4\t$1, give $2 a kiss on the cheek.\tBoth of you drink [2,5] sips.\t\t$1, geben Sie $2 einen Kuss auf die Wange.\t\tSie beide trinken [2,5] Schlucken.\tTime To Get Crazy\t\t\t
105\ttask\t4\t$1 shakes his/her ass. \t$1 drink [4,9] sips to loosen your hips.\t\t$1 schüttelt seinen Arsch.\t\t$1 Drink [4,9] Schlucke, um Ihre Hüften zu lockern.\tTime To Get Crazy\t\t\t
137\tneverHaveIEver\t4\tNever have I ever claimed social security\t\t\tIch habe noch nie die soziale Sicherheit beansprucht\t\t\tTime To Get Crazy\t\t\t
149\trule\t4\t$1 and $2 hold hands. Everytime you stop, drink!\t\t$1 and $2 you can let go of your hands now\t$1 und $2 Händen. Jedes Mal, wenn Sie anhalten, trinken Sie!\t$1 und $2 Sie können jetzt Ihre Hände loslassen\t\tTime To Get Crazy\t\t\t
161\trule\t4\t$1 and $2 switch names, who forgets drinks.\t\t$1 and $2 switch back their names\t$1 und $2 Switchnamen, der Getränke vergisst.\t$1 und $2 schalten ihre Namen zurück zurück\t\tTime To Get Crazy\t\t\t
14\twhoInTheGroup\t4\tWho of the group had the most sex partners?\t\t\tWer hatte die meisten Sexpartner?\t\t\tXXX\t\t\t
32\twhoInTheGroup\t4\tWho of the group thinks the most about sex during the day? \t\t\tWer der Gruppe denkt am meisten über Sex während des Tages?\t\t\tXXX\t\t\t
64\ttruthOrLie\t4\t$1, tell us about the craziest location you had sex at.\t\t\t$1, erzählen Sie uns von dem verrücktesten Ort, an dem Sie Sex hatten.\t\t\tXXX\t\t\t
76\ttask\t4\t$1 take of a piece of clothing.\t$1 drink [4,9] sips to loosen up a little.\t\t$1 Nehmen Sie ein Kleidungsstück.\t\t$1 Drink [4,9] schlucke, um ein wenig zu lockern.\tXXX\t\t\t
92\ttask\t4\t$1 pet $2's feet.\tBoth of you drink [2,5] sips.\t\t$1 Pet $2's Füße.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
97\ttask\t4\t$1 let $2 relieve you a piece of clothing.\t$1 drink [4,9] sips.\t\t$1 Lassen Sie Ihnen ein Kleidungsstück entlasten.\t\t$1 Drink [4,9] Schluck.\tXXX\t\t\t
123\tneverHaveIEver\t4\tNever have I ever thought about someone else during sex\t\t\tIch habe noch nie an jemanden beim Sex nachgedacht\t\t\tXXX\t\t\t
145\tneverHaveIEver\t4\tNever have I ever had sex with someone in this group\t\t\tIch hatte noch nie Sex mit jemandem in dieser Gruppe\t\t\tXXX\t\t\t
170\trule\t4\t$1 sits on $2 lap. \t\t$1, you are allowed to sit on your own ass. \t$1 sitzt auf 2 US -Dollar.\t$1, Sie dürfen auf Ihrem eigenen Arsch sitzen.\t\tXXX\t\t\t
172\trule\t4\t$1 you are the boss, every turn you choose a person that needs take of a piece of clothing. \t\tEverybody naked now? $1 cannot decide anymore. \t$1 Sie sind der Chef, in jeder Runde wählen Sie eine Person, die ein Kleidungsstück benötigt.\tJeder nackt jetzt? $1 kann sich nicht mehr entscheiden.\t\tXXX\t\t\t
195\ttournament\t4\tSnack challenge: $1 gets blindfolded and eats something of the belly of $2. If they guess right, they give 6 sips. If not they drink 6 sips.\t\t\tSnack Challenge: $1 wird mit verbundenen Augen und frisst etwas vom Bauch von $2. Wenn sie richtig erraten, geben sie 6 Schlucken. Wenn nicht, trinken sie 6 Schlucken.\t\t\tXXX\t\t\t
212\ttask\t4\t$1 flirt with $2 using only your eyes. \t\t\t$1 Flirt mit 2 $ mit nur Ihren Augen.\t\t\tXXX\t\t\t
4\twhoInTheGroup\t5\tWho of the group is the most self-critical?\t\t\tWer der Gruppe ist die selbstkritischste?\t\t\tLet's Drink\t\t\tWho in the Group
34\twhoInTheGroup\t5\tWho of the group has the most beautiful smile? \t\t\tWer der Gruppe hat das schönste Lächeln?\t\t\tLet's Drink\t\t\t
73\ttask\t5\t$1, give $2 a nice massage.\t$1 come on, don't be so lazy. Drink [4,7] sips!\t\t$1, geben 2 $ eine schöne Massage.\t\t$1 Komm schon, sei nicht so faul. Trinken [4,7] Schluck!\tLet's Drink\t\t\t
33\twhoInTheGroup\t5\tWho of the group would be the best porn actor? \t\t\tWer der Gruppe wäre der beste Porno -Schauspieler?\t\t\tTime To Get Crazy\t\t\t
41\twhoInTheGroup\t5\tWho of the group hides the most secrets? \t\t\tWer der Gruppe verbirgt die meisten Geheimnisse?\t\t\tTime To Get Crazy\t\t\t
49\ttruthOrLie\t5\t$1, tell us about your most embarrassing situation in the last year?\t\t\t$1, erzählen Sie uns von Ihrer peinlichsten Situation im letzten Jahr?\t\t\tTime To Get Crazy\t\t\t
65\ttask\t5\t$1, sing a love song for $2.\t$1 drink [4,9] sips.\t\t$1, singe ein Liebeslied für $2.\t\t$1 Drink [4,9] Schluck.\tTime To Get Crazy\t\t\t
79\ttask\t5\t$1, dance your name for us.\t$1 drink [4,9] sips to loosen your hips.\t\t$1, tanzen Sie Ihren Namen für uns.\t\t$1 Drink [4,9] Schlucke, um Ihre Hüften zu lockern.\tTime To Get Crazy\t\t\t
132\tneverHaveIEver\t5\tNever have I ever shit in a bag\t\t\tNie habe ich jemals in einer Tasche beschissen\t\t\tTime To Get Crazy\t\t\t
160\trule\t5\tCheers: Whenever $1 gives a toast, everyone drinks.\t\tYou no longer have to drink if $1 gives a toast\tProst: Immer wenn 1 US -Dollar einen Toast gibt, trinkt jeder.\tSie müssen nicht mehr trinken, wenn $1 einen Toast gibt\t\tTime To Get Crazy\t\t\t
165\trule\t5\tEveryone passes their drink to their left neighbor.\t\tEveryone passes back their drink to the original owner\tJeder übergibt sein Getränk an seinen linken Nachbarn.\tJeder gibt sein Getränk an den ursprünglichen Besitzer zurück\t\tTime To Get Crazy\t\t\t
197\ttournament\t4\tMouth-2-Mouth challenge: Going around you pass something from mouth to mouth. If it's dropped, swallowed or someone chickens out they drink [3,7] sips.\t\t\tMund-2-Mund-Herausforderung: Um dich herum zu gehen, passiere etwas von Mund zu Mund. Wenn es fallen gelassen, geschluckt oder jemand Hühner aus trinkt, trinken sie [3,7] Schlucke.\t\t\tXXX\t\t\t
27\twhoInTheGroup\t5\tWho of the group is the most likely to cheat on their partner?\t\t\tWer der Gruppe betrügt am wahrscheinlichsten ihren Partner?\t\t\tXXX\t\t\t
67\ttask\t5\t$1, give $2 a french kiss.\tBoth of you drink [2,5] sips.\t\t$1, gib $2 einen französischen Kuss.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
81\ttask\t5\t$1 simulate an orgasm.\t$1 drink [2,5] sips to get in the mood.\t\t$1 simulieren einen Orgasmus.\t\t$1 Drink [2,5] schlucke, um in Stimmung zu kommen.\tXXX\t\t\t
91\ttask\t5\t$1 pet $2's neck.\tBoth of you drink [2,5] sips.\t\t$1 Pet $2's Hals.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
101\ttask\t5\t$1 and $2 demonstrate your favorite sex position.\tLet's hope your drinking skills are better than your bedroom skills... drink [5,8] sips.\t\t$1 und $2 demonstrieren Ihre Lieblingsgeschlechtsposition.\t\tHoffen wir, dass Ihre Trinkfähigkeiten besser sind als Ihre Schlafzimmerfähigkeiten ... trinken [5,8] Schluck.\tXXX\t\t\t
104\ttask\t5\t$1 licks one finger of $2. \t$2 did you wash your hands? Better drink [3, 6] to sterilize your body with alcohol!\t\t$1 leckt einen Finger von $2.\t\t$2 Hast du deine Hände gewaschen? Besser trinken [3, 6], um Ihren Körper mit Alkohol zu sterilisieren!\tXXX\t\t\t
106\ttask\t5\t$1 kisses the person he/she finds most attractive\t\t\t$1 küsst die Person, die sie/sie findet am attraktivsten\t\t\tXXX\t\t\t
111\ttask\t5\t$1 kisses the person who he/she thinks needs it the most. \t\t\t$1 küsst die Person, von der er/sie denkt, braucht es am meisten.\t\t\tXXX\t\t\t
122\tneverHaveIEver\t5\tNever have I ever secretly read the messages of my partner\t\t\tNiemals habe ich die Nachrichten meines Partners nie heimlich gelesen\t\t\tXXX\t\t\t
139\tneverHaveIEver\t5\tNever have I ever had sex when there were others in the room\t\t\tIch hatte noch nie Sex, als andere im Raum waren\t\t\tXXX\t\t\t
155\trule\t5\tWhile talking to someone you need to stare at their crutch. Who forgets drinks.\t\tYou don't have to keep staring at peoples crutches anymore you little perverts.\tWährend Sie mit jemandem sprechen, müssen Sie auf seine Krücke starren. Wer vergisst Getränke.\tSie müssen nicht mehr auf die Krücken von Menschen anstarren, Sie kleine Perverse.\t\tXXX\t\t\t
198\ttask\t5\t$1 gently spanks $2's butt. \tWhoever chickens out drinks [2,6] sips.\t\t$1 sanft sanft $2's Hintern.\t\tWer immer Hühner trinkt [2,6].\tXXX\t\t\t
202\ttask\t5\tCupid: $1 pick 2 players. They then have to make out.\tWhoever chickens out drinks [2,6] sips.\t\tCupid: $1 Wählen Sie 2 Spieler. Sie müssen dann rummachen.\t\tWer immer Hühner trinkt [2,6].\tXXX\t\t\t
205\ttask\t5\tTop-less: Everyone keeps playing top-less. Who chickens out or gets dressed again drinks [3,8] sips\t\tYou don't have to keep playing top-less, if you don't want to.\tTop-Less: Jeder spielt immer wieder Top-Less. Wer Hühner ausgehen oder sich wieder anziehen, trinkt [3,8] Schluck [3,8].\tSie müssen nicht weiterhin Top-Less spielen, wenn Sie nicht wollen.\t\tXXX\t\t\t
12\twhoInTheGroup\t6\tWho of the group is the most indecisive?\t\t\tWer der Gruppe ist der unentschlossenste?\t\t\tLet's Drink\t\t\t
162\trule\t6\tThe floor is lava, whoever touches it drinks.\t\tThe floor is just a regular old floor again.\tDer Boden ist Lava, wer es berührt, trinkt.\tDer Boden ist wieder ein normaler alter Boden.\t\tLet's Drink\t\t\t
8\twhoInTheGroup\t6\tWho of the group is the most jealous?\t\t\tWer der Gruppe ist am eifersüchtigsten?\t\t\tTime To Get Crazy\t\t\t
44\ttruthOrLie\t6\t$1, tell us about the time first time you had sex. \t\t\t$1, erzählen Sie uns über das erste Mal, dass Sie Sex hatten.\t\t\tTime To Get Crazy\t\t\t
82\ttask\t6\t$1 make $2 up to 10 serious compliments. Give away 1 sip for each one. \tYou can't come up with any? Drink [4,8] sips.\t\t$1 verdienen $2 bis zu 10 schwerwiegende Komplimente. Geben Sie 1 Schluck für jeden.\t\tSie können sich keine einfallen lassen? Trinken [4,8] Schluck.\tTime To Get Crazy\t\t\t
116\tneverHaveIEver\t6\tNever have I ever had a threesome\t\t\tIch hatte noch nie einen Dreier\t\t\tTime To Get Crazy\t\t\t
38\twhoInTheGroup\t6\tWho of the group is most open to an orgie? \t\t\tWer der Gruppe ist am offensten für eine Orgie?\t\t\tXXX\t\t\t
51\ttruthOrLie\t6\t$1, tell us about your most secret passion.\t\t\t$1, erzählen Sie uns von Ihrer geheimsten Leidenschaft.\t\t\tXXX\t\t\t
72\ttask\t6\t$1, $2 make out.\tBoth of you drink [2,5] sips.\t\t$1, $2 Make Out.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
77\ttask\t6\t$1 and $2 switch [2,4] pieces of clothing.\t$1 and $2 take off a piece of clothing instead.\t\t$1 und $2 Switch [2,4] Kleidungsstücke.\t\t$1 und $2 nehmen stattdessen ein Kleidungsstück ab.\tXXX\t\t\t
84\ttask\t6\t$1 lick the neck of $2.\tWhoever chickend out drinks [2,5] sips\t\t$1 leck den Hals von $2.\t\tWer auch immer einen Getränke ausübt [2,5], schluckt zu\tXXX\t\t\t
94\ttask\t6\t$1 pet $2's breasts.\tBoth of you drink [2,5] sips.\t\t$1 Pet $2's Brüste.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
108\ttask\t6\t$1 take off $2 top.\t$1 show us how it's done. Take off your top!\t\t$1 ab 2 US -Dollar Top.\t\t$1 Zeigen Sie uns, wie es gemacht wird. Zieh dein Oberteil aus!\tXXX\t\t\t
126\tneverHaveIEver\t6\tNever have I ever said "I love you" and didn't mean it\t\t\tIch habe nie gesagt "Ich liebe dich" und meinte es nicht\t\t\tXXX\t\t\t
196\ttournament\t6\tGoing around everyone takes off a piece of clothing. Whoever chickens out first drinks [5,8] sips.\t\t\tWenn alle herumgehen, nimmt alle ein Kleidungsstück aus. Wer auch immer Hühner aus den ersten Getränken [5,8] ausübt.\t\t\tXXX\t\t\t
207\ttask\t6\t$1 wear your underwear over your pants\tI knew you were no Superman, drink [3,6] sips until you believe in your super powers.\t\t$1 Tragen Sie Ihre Unterwäsche über Ihren Hosen\t\tIch wusste, dass du kein Superman bist, trinke [3,6] Schluck, bis du an deine Superkräfte glaubst.\tXXX\t\t\t
168\trule\t7\tNo-laughing challenge. Who laughs drinks.\t\tYou can stop holding back your laughter.\tNo-Laughing Challenge. Wer lacht Getränke.\tSie können aufhören, Ihr Lachen zurückzuhalten.\t\tLet's Drink\t\t\t
47\ttruthOrLie\t7\t$1, tell us about your weirdest tick.\t\t\t$1, erzählen Sie uns von Ihrer seltsamsten Zecke.\t\t\tTime To Get Crazy\t\t\t
78\ttask\t7\t$1 praise the absolute stunning beauty of $2 if $2 is happy give away [3,5] sips otherwise drink them.\t\t\t$1 Lob die absolut atemberaubende Schönheit von $2 $, wenn 2 $ glücklich ist, gib [3,5] zu trinken, trinken sie ansonsten.\t\t\tTime To Get Crazy\t\t\t
96\ttask\t7\t$1 declare your love for $2.\t$1, what's not to love about $2?  $1 tell us 3 things you love about $2.\t\t$1 deklarieren Sie Ihre Liebe für $2.\t\t$1, was ist nicht mit 2 $ zu lieben? $1 Erzählen Sie uns 3 Dinge, die Sie über 2 $ lieben.\tTime To Get Crazy\t\t\t
98\ttask\t7\t$1 and $2 dance intensively to a song of their choosing.\tBoth of you drink [2,5] sips.\t\t$1 und $2 tanzen intensiv zu einem Lied ihrer Wahl.\t\tSie beide trinken [2,5] Schlucken.\tTime To Get Crazy\t\t\t
107\ttask\t7\t$1 take off $2 pants. \tWhoever still wears pants drinks [3,6].\t\t$1 abnehmen $2 Hosen.\t\tWer immer noch Hosengetränke trägt [3,6].\tTime To Get Crazy\t\t\t
163\trule\t7\t$1, I think you had enough. Switch to water for now.\t\t$1 you look alot better, you can stop drinking water now\t$1, ich denke du hattest genug. Wechseln Sie vorerst zum Wasser.\t$1 Sie sehen viel besser aus, Sie können jetzt aufhören, Wasser zu trinken\t\tTime To Get Crazy\t\t\t
69\ttask\t7\t$1, give $2 a kiss on the naked butt.\t$1 drink [4,9] sips.\t\t$1, geben Sie $2 einen Kuss auf den nackten Hintern.\t\t$1 Drink [4,9] Schluck.\tXXX\t\t\t
89\ttask\t7\t$1 give $2 a lapdance.\t$1 drink [4,9] sips.\t\t$1 Geben Sie $2 pro Lapdance.\t\t$1 Drink [4,9] Schluck.\tXXX\t\t\t
175\ttournament\t7\tGoing around everyone tell the name of a person he/she has slept with. Who can't or repeats something drinks [2,6] sips.\t\t\tAlle zu gehen, erzählen den Namen einer Person, mit der er/sie geschlafen hat. Wer kann etwas trinken oder wiederholt [2,6] Schluck.\t\t\tXXX\t\t\t
176\trule\t7\t$1 is getting blindfolded. If there is peaking, there will be drinking.\t\t$1 can have their vision back now.\t$1 wird die Augen verbunden. Wenn es ein Peaking gibt, wird es trinken.\t$1 kann jetzt ihre Vision zurück haben.\t\tXXX\t\t\t
70\ttask\t8\t$1, give $2 a kiss on the butt. If $2 presents their bare ass, $2 gives away [3,6] sips.\tWho chickend out drinks [4,7] sips.\t\t$1, geben Sie $2 einen Kuss auf den Hintern. Wenn $2 ihren nackten Arsch präsentiert, verschenkt 2 US -Dollar [3,6] Schlucken.\t\tWer trinkt trinkt [4,7].\tTime To Get Crazy\t\t\t
74\ttask\t8\t$1, take your phone, open WhatsApp and read us the three last messages of the latest chat (no groups).\tDrink [3,8] sips to keep your privacy.\t\t$1, nehmen Sie Ihr Telefon, öffnen Sie WhatsApp und lesen Sie uns die drei letzten Nachrichten des neuesten Chat (keine Gruppen).\t\tTrinken Sie [3,8] Schluck, um Ihre Privatsphäre zu halten.\tTime To Get Crazy\t\t\t
80\ttask\t8\t$1 ask $2 to marry you. If $2 says yes give away [6,10] sips, it $2 says no drink 3 sips.\t$1 you don't deserve $2, drink [4,6] sips standing alone in the corner.\t\t$1 Bitten Sie $2, Sie zu heiraten. Wenn 2 US -Dollar sagen, ja, gib [6,10] Schlucken weg, es heißt 2 $, kein Getränk 3 Schluck.\t\t$1 Sie verdienen nicht 2 $, trinken [4,6], die alleine in der Ecke stehen.\tTime To Get Crazy\t\t\t
117\tneverHaveIEver\t8\tNever have I ever sucked a dick\t\t\tIch habe noch nie einen Schwanz gesaugt\t\t\tTime To Get Crazy\t\t\t
83\ttask\t8\t$1 lick the nippel of $2.\tWhoever chickend out drinks [2,5] sips\t\t$1 leck den Nippel von $2.\t\tWer auch immer einen Getränke ausübt [2,5], schluckt zu\tXXX\t\t\t
90\ttask\t8\t$1 pet $2's intimate area.\tBoth of you drink [2,5] sips.\t\t$1 Haustier $2's intimes Gebiet.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
99\ttask\t8\t$1 help $2 into a piece of clothing.\tBoth of you take off a piece of clothing.\t\t$1 helfen 2 $ in ein Kleidungsstück.\t\tSie beide nehmen ein Kleidungsstück aus.\tXXX\t\t\t
112\ttask\t8\t$1 put your hand into $2's panties.\tWhoever chickend out drinks [2,5] sips.\t\t$1 Geben Sie Ihre Hand in das Höschen von 2 US -Dollar.\t\tWer auch immer trinkt [2,5].\tXXX\t\t\t
192\ttournament\t9\tStrip challenge: Who is naked the fastest gives [5,9] sips.\t\t\tStrip Challenge: Wer ist nackt am schnellsten gibt [5,9] Schlucke.\t\t\tXXX\t\t\t
200\ttask\t8\t$1 pull down $2's underwear only with your teeth.\tWhoever chickens out drinks [2,6] sips.\t\t$1 ziehen 2 US -Dollar nur mit den Zähnen $2 ab.\t\tWer immer Hühner trinkt [2,6].\tXXX\t\t\t
201\ttask\t8\t$1 gently pinch $2's nipple.\tWhoever chickens out drinks [2,6] sips.\t\t$1 sanft $2's Nippel.\t\tWer immer Hühner trinkt [2,6].\tXXX\t\t\t
42\twhoInTheGroup\t9\tWhose personality is most fake? \t\t\tWessen Persönlichkeit ist am falschsten?\t\t\tTime To Get Crazy\t\t\t
66\ttask\t6\t$1 kiss $2 on the cheek.\tBoth of you drink [2,5] sips.\t\t$1 Kuss $2 auf der Wange.\t\tSie beide trinken [2,5] Schlucken.\tTime To Get Crazy\t\t\t
71\ttask\t9\t$1 kiss the nipple of $2.\tWho chickend out drinks [4,7] sips.\t\t$1 Küss die Brustwarze von $2.\t\tWer trinkt trinkt [4,7].\tXXX\t\t\t
109\ttask\t9\t$1 take off $2 panties.\tBoth of you drink [2,5] sips.\t\t$1 ab 2 $ Slip.\t\tSie beide trinken [2,5] Schlucken.\tXXX\t\t\t
88\ttask\t10\t$1 strip for $2.\t$1 finish your drink.\t\t$1 Streifen für $2.\t\t$1 Beenden Sie Ihr Getränk.\tXXX\t\t\t
214\twhoInTheGroup\t\tWho of the group will marry next?\t\t\tWer der Gruppe wird als nächstes heiraten?\t\t\tBride to Be\t\t\t
215\twhoInTheGroup\t\tWho of the group is closest to the groom? \t\t\tWer der Gruppe ist dem Bräutigam am nächsten?\t\t\tBride to Be\t\t\t
216\twhoInTheGroup\t\tWho of the group knows most of the bride's secrets? \t\t\tWer von der Gruppe kennt die meisten Geheimnisse der Braut?\t\t\tBride to Be\t\t\t
217\twhoInTheGroup\t\tWho of the group will be the most drunk at the wedding? \t\t\tWer der Gruppe wird bei der Hochzeit am meisten betrunken sein?\t\t\tBride to Be\t\t\t
218\tneverHaveIEver\t\tNever have I ever been on a bachelorette party.\t\t\tIch war noch nie auf einer Junggesellenparty.\t\t\tBride to Be\t\t\t
219\tneverHaveIEver\t\tNever have I ever been wasted on a wedding party. \t\t\tIch bin noch nie auf einer Hochzeitsfeier verschwendet worden.\t\t\tBride to Be\t\t\t
220\tneverHaveIEver\t\tNever have I ever been on a marriage of a close friend. \t\t\tIch war noch nie in einer Ehe eines engen Freundes.\t\t\tBride to Be\t\t\t
221\tneverHaveIEver\t\tNever have I ever thought about marrying my (ex)boyfriend/(ex)girlfriend.\t\t\tIch habe noch nie darüber nachgedacht, meinen (Ex) Freund/(Ex) -Glieben zu heiraten.\t\t\tBride to Be\t\t\t
222\tneverHaveIEver\t\tNever have I ever spoken with my (ex)partner about our marriage. \t\t\tIch habe noch nie mit meinem (Ex) Partner über unsere Ehe gesprochen.\t\t\tBride to Be\t\t\t
223\ttruthOrLie\t\t$1, tell us about your most embarrassing moment on a wedding.\t\t\t$1, erzählen Sie uns von Ihrem peinlichsten Moment auf einer Hochzeit.\t\t\tBride to Be\t\t\t
224\ttruthOrLie\t\t$1, tell us about your perfect wedding.\t\t\t$1, erzählen Sie uns von Ihrer perfekten Hochzeit.\t\t\tBride to Be\t\t\t
225\ttruthOrLie\t\t$1, tell us about a person you would marry without thinking about it twice.\t\t\t$1, erzählen Sie uns von einer Person, die Sie heiraten würden, ohne zweimal darüber nachzudenken.\t\t\tBride to Be\t\t\t
226\ttruthOrLie\t\t$1, tell us about your weirdest moment with the bride.\t\t\t$1, erzählen Sie uns von Ihrem seltsamsten Moment mit der Braut.\t\t\tBride to Be\t\t\t
227\ttruthOrLie\t\t$1, tell us for what you secretly envy the bride. \t\t\t$1, sagen Sie uns, was Sie heimlich die Braut beneiden.\t\t\tBride to Be\t\t\t
228\ttruthOrLie\t\t$1, tell us about your perfect bachelorette party. \t\t\t$1, erzählen Sie uns von Ihrer perfekten Bachelorette -Party.\t\t\tBride to Be\t\t\t
229\tneverHaveIEver\t\tNever have I ever heard the bride having sex.\t\t\tIch habe noch nie gehört, dass die Braut Sex hat.\t\t\tBride to Be\t\t\t
230\trule\t\tEverybody needs to address the bride with "her Majesty the Queen". Who forgets drinks. \t\tStop being humble, you can call the bride whatever you want. \tJeder muss die Braut mit "Ihre Majestät der Königin" ansprechen. Wer vergisst Getränke.\tHör auf, bescheiden zu sein, du kannst die Braut so nennen, wie du es willst.\t\tBride to Be\t\t\t
231\trule\t\t$1 and $2 hold hands. \t\tYou are allowed to let got (if you want).\t$1 und $2 Händen.\tSie dürfen GOT lassen (wenn Sie wollen).\t\tBride to Be\t\t\t
232\trule\t\tEverybody needs to talk to the bride as if she were a toddler. \t\tThe bride is old enough, you can treat her normal again (if you want). \tJeder muss mit der Braut sprechen, als wäre sie ein Kleinkind.\tDie Braut ist alt genug, Sie können sie wieder normal behandeln (wenn Sie wollen).\t\tBride to Be\t\t\t
233\ttask\t\t$1, try to convince the bride that she should marry you instead of the groom. \t\t\t$1, versuchen Sie die Braut davon zu überzeugen, dass sie Sie anstelle des Bräutigams heiraten sollte.\t\t\tBride to Be\t\t\t
234\ttask\t\tThe bride is tired, $1 animate the bride to do some exercising with you. \t\t\tDie Braut ist müde, 1 US -Dollar die Braut, um mit Ihnen zu trainieren.\t\t\tBride to Be\t\t\t
235\ttask\t\tIt's time to give some compliments. The bride needs tell everybodies best quality. \t\t\tEs ist Zeit, einige Komplimente zu machen. Die Braut braucht allen besten Qualität.\t\t\tBride to Be\t\t\t
236\ttask\t\t$1 and $2, time for some practice. Stand up and  express your feelings on the bride's wedding in a speech. \t\t\t$1 und $2, Zeit für einige Üben. Steh auf und drücke deine Gefühle in der Hochzeit der Braut in einer Rede aus.\t\t\tBride to Be\t\t\t
237\ttask\t\t$1, sing a wedding song for the bride. \t\t\t$1, singe ein Hochzeitslied für die Braut.\t\t\tBride to Be\t\t\t
238\ttask\t\t$1, the bride needs to practice. Time for a little dance. \t\t\t$1, die Braut muss üben. Zeit für einen kleinen Tanz.\t\t\tBride to Be\t\t\t
239\ttask\t\t$1, you can decide! What does $2 needs to do on the wedding?\t\t\t$1, Sie können sich entscheiden! Was muss 2 US -Dollar für die Hochzeit tun?\t\t\tBride to Be\t\t\t
240\ttask\t\tBride, it's your last chance. Try to flirt with somebody around who does not belong to the group. \t\t\tBraut, es ist deine letzte Chance. Versuchen Sie, mit jemandem zu flirten, der nicht zur Gruppe gehört.\t\t\tBride to Be\t\t\t
241\ttask\t\t$1, your are looking for a potential wedding partner for $2. Have a look in your whatsapp contacts and propose somebody emphasizing his/her qualities. \t\t\t$1, Sie suchen einen potenziellen Hochzeitspartner für 2 US -Dollar. Schauen Sie sich Ihre WhatsApp -Kontakte an und schlagen Sie jemanden vor, der seine Qualitäten betont.\t\t\tBride to Be\t\t\t
242\ttask\t\tNo secrets anymore. The bride has to type in "sex" in the whatsapp chat with the groom and read out loud the first message. \t\t\tKeine Geheimnisse mehr. Die Braut muss "Sex" im WhatsApp -Chat mit dem Bräutigam eingeben und die erste Nachricht laut vorlesen.\t\t\tBride to Be\t\t\t
243\ttask\t\tTime for a new look! $1 and $2, decorate the bride with whatever you can find. \t\t\tZeit für einen neuen Look! $1 und $2, dekorieren Sie die Braut mit allem, was Sie finden können.\t\t\tBride to Be\t\t\t
244\ttask\t\t$1, open a dating app and convince the bride to marry the first person you swipe. \t\t\t$1, eröffnen Sie eine Dating -App und überzeugen Sie die Braut, die erste Person zu heiraten, die Sie wischen.\t\t\tBride to Be\t\t\t
245\twhoInTheGroup\t\tWho of the group will be too late to the wedding ceremony? \t\t\tWer der Gruppe wird zu spät zur Hochzeitszeremonie sein?\t\t\tBride to Be\t\t\t
246\twhoInTheGroup\t\tWho of the group will dance the most at the wedding ceremony? \t\t\tWer der Gruppe wird bei der Hochzeitszeremonie am meisten tanzen?\t\t\tBride to Be\t\t\t
247\ttruthOrLie\t\t$1, tell us about your favourite moment with the bride. \t\t\t$1, erzählen Sie uns von Ihrem Lieblingsmoment mit der Braut.\t\t\tBride to Be\t\t\t
248\ttruthOrLie\t\t$1, tell us about the most interesting talk you had with the groom. \t\t\t$1, erzählen Sie uns von dem interessantesten Gespräch, das Sie mit dem Bräutigam geführt haben.\t\t\tBride to Be\t\t\t
249\ttask\t\tMassage-Round: Everyone massages their left neighbor.\t\t\tMassage-Runden: Jeder massiert seinen linken Nachbarn.\t\t\tBride to Be\t\t\t
56\ttruthOrLie\t3\t$1, tell us about your secret addiction.\t\t\t$1, erzählen Sie uns von Ihrer geheimen Sucht.\t\t\tLet's Drink\t\t\t
57\ttruthOrLie\t1\t$1, tell us about your greatest talents.\t\t\t$1, erzählen Sie uns von Ihren größten Talenten.\t\t\tLet's Drink\t\t\t
58\ttruthOrLie\t4\t$1, tell us about your weirdest free time activity. \t\t\t$1, erzählen Sie uns von Ihrer seltsamsten Freizeitaktivität.\t\t\tLet's Drink\t\t\t
59\ttruthOrLie\t5\t$1, tell us about your biggest misunderstanding with a colleague. \t\t\t$1, erzählen Sie uns von Ihrem größten Missverständnis mit einem Kollegen.\t\t\tLet's Drink\t\t\t
61\ttruthOrLie\t1\t$1, tell us about something that really annoys you. \t\t\t$1, erzählen Sie uns von etwas, das Sie wirklich nervt.\t\t\tLet's Drink\t\t\t
54\ttruthOrLie\t4\t$1, tell us about your last sexual dream.\t\t\t$1, erzählen Sie uns von Ihrem letzten sexuellen Traum.\t\t\tXXX\t\t\t
55\ttruthOrLie\t8\t$1, tell us about your best orgasm.\t\t\t$1, erzählen Sie uns von Ihrem besten Orgasmus.\t\t\tXXX\t\t\t
60\ttruthOrLie\t3\t$1, tell us about your secret flame as a teenager. \t\t\t$1, erzählen Sie uns von Ihrer geheimen Flamme als Teenager.\t\t\tXXX\t\t\t
250\ttask\t6\t$1, tell a joke. If no one laughs drink 4\t\t\t$1, erzählen Sie einen Witz. Wenn niemand lacht, trinken 4\t\t\tLet's Drink\t\t\t
251\ttask\t8\t$1 cry like a baby\t\t\t$1 weinen wie ein Baby\t\t\tLet's Drink\t\t\t
252\tpunishment\t3\t$1 turn your back to everyone\t\t\t$1 wende alle den Rücken an alle\t\t\tTime To Get Crazy\t\t\t
253\twhoInTheGroup\t4\tWho of the group spent the most money this year?\t\t\tWer der Gruppe gibt dieses Jahr das meiste Geld aus?\t\t\tSilvester 2022\t\t\t
254\twhoInTheGroup\t3\tWho of the group made the most new friends?\t\t\tWer der Gruppe hat die neuen Freunde gefunden?\t\t\tSilvester 2022\t\t\t
255\twhoInTheGroup\t2\tWho of the group will have the worst hangover tomorrow?\t\t\tWer der Gruppe wird morgen den schlimmsten Kater haben?\t\t\tSilvester 2022\t\t\t
256\twhoInTheGroup\t1\tWho of the group has the most beautiful handwriting?\t\t\tWer der Gruppe hat die schönste Handschrift?\t\t\tSilvester 2022\t\t\t
257\twhoInTheGroup\t8\tWho of the group had the most sex this year?\t\t\tWer der Gruppe hatte dieses Jahr am meisten Sex?\t\t\tSilvester 2022\t\t\t
258\twhoInTheGroup\t5\tWho of the group has the most secrets to hide?\t\t\tWer der Gruppe hat am meisten zu verbergen (Geheimnisse)?\t\t\tSilvester 2022\t\t\t
259\twhoInTheGroup\t7\tWho of the group was closest to a burnout last year?\t\t\tWer der Gruppe war letztes Jahr einem Burnout am nächsten?\t\t\tSilvester 2022\t\t\t
260\twhoInTheGroup\t4\tWho of the group got the biggest surprise this year?\t\t\tWer der Gruppe hat in diesem Jahr die größte Überraschung?\t\t\tSilvester 2022\t\t\t
261\twhoInTheGroup\t6\tWho of the group will make the most money next year? \t\t\tWer der Gruppe wird nächstes Jahr das meiste Geld verdienen?\t\t\tSilvester 2022\t\t\t
262\twhoInTheGroup\t3\tWho of the group will loose the most hair next year? \t\t\tWer der Gruppe wird nächstes Jahr die meisten Haare verlieren?\t\t\tSilvester 2022\t\t\t
263\twhoInTheGroup\t1\tWho of  the group has traveled the most kilometers? \t\t\tWer der Gruppe hat die meisten Kilometer gereist?\t\t\tSilvester 2022\t\t\t
264\twhoInTheGroup\t2\tWho of the group has was flying the most last year?\t\t\tWer der Gruppe hat das Flugzeug letztes Jahr am meisten genutzt?\t\t\tSilvester 2022\t\t\t
265\twhoInTheGroup\t9\tWho of the group will took the shit of the year? \t\t\tWer der Gruppe wird nächstes Jahr das größte Kot machen?\t\t\tSilvester 2022\t\t\t
266\twhoInTheGroup\t7\tWho of the group will suffer the most next year? \t\t\tWer der Gruppe wird das meiste nächste Jahr leiden?\t\t\tSilvester 2022\t\t\t
268\twhoInTheGroup\t3\tWho of the group will party the most next year? \t\t\tWer der Gruppe wird das meiste nächste Jahr feiern?\t\t\tSilvester 2022\t\t\t
269\tneverHaveIEver\t3\tNever have I ever I puked on New Year´s eve\t\t\tNie habe ich nie am Silvesterabend gekotzt\t\t\tSilvester 2022\t\t\t
270\tneverHaveIEver\t4\tNever have I ever had sex on a New Year\`s party\t\t\tIch hatte noch nie Sex auf einer Neujahrsparty\t\t\tSilvester 2022\t\t\t
271\tneverHaveIEver\t2\tNever have I ever slept through New Years\t\t\tIch habe noch nie durch Neujahr geschlafen\t\t\tSilvester 2022\t\t\t
272\tneverHaveIEver\t4\tNever have I ever been sick on New Year\`s eve\t\t\tIch war noch nie am Silvesterabend krank\t\t\tSilvester 2022\t\t\t
273\tneverHaveIEver\t3\tNever have I ever been to drunk too celebrate New Year\`s eve\t\t\tIch war noch nie in Betrunkenheit, um den Silvesterabend zu feiern\t\t\tSilvester 2022\t\t\t
274\tneverHaveIEver\t4\tNever have I ever hurt myself with fireworks\t\t\tIch habe mich nie mit Feuerwerksarbeit verletzt\t\t\tSilvester 2022\t\t\t
275\tneverHaveIEver\t2\tNever have I ever lost my party crew on New Year´s eve\t\t\tHabe ich noch nie meine Party -Crew am Silvesterabend verloren?\t\t\tSilvester 2022\t\t\t
276\tneverHaveIEver\t6\tNever have I ever kissed a stranger on New Year\`s eve\t\t\tIch habe noch nie einen Fremden am Silvesterabend geküsst?\t\t\tSilvester 2022\t\t\t
277\tneverHaveIEver\t7\tNever have I ever cried on a New Year\`s eve party\t\t\tIch habe noch nie auf einer Silvesterparty geweint?\t\t\tSilvester 2022\t\t\t
278\tneverHaveIEver\t4\tNever have I ever been on more than 2 New Year\`s eve parties\t\t\tWar ich noch nie mehr als 2 Silvesterpartys?\t\t\tSilvester 2022\t\t\t
279\tneverHaveIEver\t2\tNever have I ever crashed a New Year´s eve party\t\t\tHabe ich noch nie eine Silvesterparty abgestürzt?\t\t\tSilvester 2022\t\t\t
280\tneverHaveIEver\t5\tNever have I ever been naked on a New Year´s eve party\t\t\tWar ich noch nie auf einer Silvesterparty nackt?\t\t\tSilvester 2022\t\t\t
281\tneverHaveIEver\t8\tNever have I ever made out with more than one person on New Year\`s eve\t\t\tNoch nie habe ich am Silvesterabend mit mehr als einer Person rumgemacht?\t\t\tSilvester 2022\t\t\t
282\ttask\t4\t$1, give $2 a New Year\`s hug.\t\t\t$1, umarmt 2 Dollar pro Jahr.\t\t\tSilvester 2022\t\t\t
283\ttask\t3\t$2, pour a short in $1 mouth. \t\t\t$2, einen kurzen in 1 $ Mund gießen.\t\t\tSilvester 2022\t\t\t
284\ttask\t7\t$1, give $2 a flirty compliment.\t\t\t$1, geben 2 $ ein flirtiges Kompliment.\t\t\tSilvester 2022\t\t\t
285\ttask\t5\t$1, give a speech on $2 success story of the year.\t\t\t$1, halten Sie eine Rede über 2 -Dollar -Erfolgsgeschichte des Jahres des Jahres.\t\t\tSilvester 2022\t\t\t
286\ttask\t7\t$1, give $2 a taks her/she needs to acomplish before midnight.\t\t\t$1, gib $2 a taks sie/sie muss heute Abend Paromplish.\t\t\tSilvester 2022\t\t\t
287\ttask\t4\t$1, you will be responsbile to open the champaner at midnight. \t\t\t$1, Sie werden um Mitternacht den Champaner eröffnen.\t\t\tSilvester 2022\t\t\t
288\ttask\t9\t$1, give a New Year´s speech to your imaginary people from your balcony. \t\t\t$1, halten Sie Ihren imaginären Menschen aus Ihrem Balkon eine Neujahrsrede.\t\t\tSilvester 2022\t\t\t
289\ttask\t6\t$1, you have 20 seconds to invent a New Year\`s dance and motivate all people to dance at midnight. \t\t\t$1, Sie haben 20 Sekunden Zeit, um den Tanz eines neuen Jahres zu erfinden und alle Menschen zum Tanzen um Mitternacht zu motivieren.\t\t\tSilvester 2022\t\t\t
291\ttask\t9\t$1, give $2 a New Year\`s kiss. \t\t\t$1, geben Sie 2 $ einen neuen Jahreskuss.\t\t\tSilvester 2022\t\t\t
292\trule\t8\tFeets up! Everybody puts his/her feet on the table. \t\tYou can sit normal again!\t\t\t\tSilvester 2022\t\t\t
293\trule\t1\tDrinking buddies! If $2 drinks, $1 drinks as well. \t\tStop being drinking buddies.\tTrinkkumpels! Wenn 2 $ Getränke, auch 1 -Dollar -Getränke.\t\t\tSilvester 2022\t\t\t
294\ttask\t5\t$1 open a new bottle of champagne and let it pass until it is empty. \t\t\t$ 1 Öffnen Sie eine neue Flasche Champagner und lassen Sie sie passieren, bis sie leer ist.\t\t\tSilvester 2022\t\t\t
295\ttask\t7\t$1, make a selfie with the whole group and post it in your WhatsApp group. \t\t\t$ 1, mach ein Selfie mit der gesamten Gruppe und veröffentlichen Sie es in Ihrer App -Gruppe, was ist.\t\t\tSilvester 2022\t\t\t
296\ttask\t6\tShoot a video where everbody tells his/her New Year\`s resolution. $1 is responsbile to share it with you at the end of next year. \t\t\tSchießen Sie ein Video, wo immer immer seine neue Jahresbeschlüsse erzählt. $ 1 ist Verantwortung, um es mit Ihnen zu teilen, um Ende nächsten Jahres zu beenden.\t\t\tSilvester 2022\t\t\t
297\trule\t2\t$1 you are Medusa... No one is allowed to look into $1's eyes. Who forgets drinks\t\t$1 is cured, you can look at $1's eyes again.\t$ 1 Sie sind Medusa ... niemand darf sich in die Augen von $ 1 schauen. Wer vergisst Getränke\t$ 1 ist geheilt, Sie können sich wieder 1 $ 1 ansehen.\t\tSilvester 2022\t\t\t
298\trule\t3\tTo many alcoholics! Everybody only drinks water from now on. \t\tHow boring it get's without alcohol... Back to your drinks. \tZu viel Alkoholikern! Erverybody trinkt von nun an nur Wasser.\t\t\tSilvester 2022\t\t\t
299\ttask\t6\tYou are a self help group. Everybody introduces him/her self telling the others about his/her problem. \t\t\tSie sind eine Selbsthilfegruppe. Jeder stellt sich/ihr selbst vor, wenn er den anderen über sein Problem sagt.\t\t\tSilvester 2022\t\t\t
300\trule\t5\tSpeaking is not allowed anymore. Only singing in a musical or opera style. \t \tThe show is over. Your can speak again.\t\t\t\tSilvester 2022\t\t\t
301\trule\t4\tEverytime you drink you give a special toast on the New Year? \t \tThursty? Just drink without a toast.\t\t\t\tSilvester 2022\t\t\t
302\ttask\t7\tAt midnight you will greet everybody you will meet on the street with "Guten Rutsch". \t\t\t\t\t\tSilvester 2022\t\t\t
303\ttask\t3\tMassage time! Everbody gives a massage to his/her left neighbour. \t\t\t\t\t\tSilvester 2022\t\t\t
304\ttask\t2\tEverybody swap seat with the person opposite to you. \t\t\t\t\t\tSilvester 2022\t\t\t
305\ttournament\t1\tThumb fight. Who wins gives 5 sips. \t\t\t\t\t\tSilvester 2022\t\t\t
306\ttournament\t6\tWho spent the most time on their phone during this month. Check you phone! The one most addicted drinks 2 sips. \t\t\t\t\t\tSilvester 2022\t\t\t
307\ttask\t3\t$1, throw a coin. If you are right, everbody else needs to drink. If you are wrong, you need to drink. \t\t\t\t\t\tSilvester 2022\t\t\t
308\ttask\t7\t$1, draw a vagina and explaine everbody it\`s composition. \t\t\t\t\t\tXXX\t\t\t
309\ttask\t8\tEverybody relax your nipples. \t\t\t\t\t\tSilvester 2022\t\t\t
310\ttask\t6\tHappy hour. Everbody mix $1 a drink. \t\t\t\t\t\tSilvester 2022\t\t\t
311\ttask\t9\t$1 and $2, swap your bras. \t\t\t\t\t\tSilvester 2022\t\t\t
312\ttask\t4\tShow us you 13th picture on your phone. \t\t\t\t\t\tSilvester 2022\t\t\t
313\ttask\t3\tEverbody swap socks with his/her left neighbor. \t\t\t\t\t\tSilvester 2022\t\t\t
314\ttask\t3\t$2, draw a tattoo on $1 arm. \t\t\t\t\t\tSilvester 2022\t\t\t
315\ttask\t9\t$2, draw a tattoo on $1 butt. \t\t\t\t\t\tSilvester 2022\t\t\t
316\ttask\t1\t$1 and $2 swap seats. \t\t\t\t\t\tSilvester 2022\t\t\t
317\ttask\t5\t$1, sit on the lap of $2. \t\t\t\t\t\tSilvester 2022\t\t\t
318\ttask\t9\tEverytime you are on the toilet, send a selfie to the group for the next 24 hours. \t\t\t\t\t\tSilvester 2022\t\t\t
319\ttask\t4\t$1 needs to wear glasses (if she/he already wears some already, he/she needs others). \t\t\t\t\t\tSilvester 2022\t\t\t
320\ttask\t7\t$1, flirt with your left neighbour. If you aren\`t successfull, try you right neigbour. \t\t\t\t\t\tSilvester 2022\t\t\t
321\ttask\t5\t$1, run trough the apartment as fast as you can. Without using your legs!\t\t\t\t\t\tSilvester 2022\t\t\t
322\trule\t6\tHoney: Everybody gets a new cute nickname. If you use the real name you need to drink! \t\tYou can call your friends the way you want. \t\t\t\tSilvester 2022\t\t\t
323\ttournament\t3\t$1 describes a movie. Who guesses it first, can give three sips. \t\t\t\t\t\tSilvester 2022\t\t\t
324\ttruthOrLie\t5\tTell us about your weirdest moment this year. \t\t\t\t\t\tSilvester 2022\t\t\t
325\ttruthOrLie\t6\tTell us aboout your secret dream for next year. \t\t\t\t\t\tSilvester 2022\t\t\t
326\ttruthOrLie\t2\tTell a story where you were either drunk or a child. The others have to decide: drunk or child. \t\t\t\t\t\tSilvester 2022\t\t\t
327\ttruthOrLie\t4\tTell us about your craziest party outfit last year. \t\t\t\t\t\tSilvester 2022\t\t\t
328\ttruthOrLie\t5\tTell us something you really want to do next year. \t\t\t\t\t\tSilvester 2022\t\t\t
329\ttruthOrLie\t8\tTell about you biggest failure last year. \t\t\t\t\t\tSilvester 2022\t\t\t
330\twhoInTheGroup\t7\tWho of the group will most likely get pregnant next year?\t\t\t\t\t\tSilvester 2022\t\t\t
331\ttask\t8\tSend your best friend a voice message telling him/her how proud you are of him/her. \t\t\t\t\t\tSilvester 2022\t\t\t
332\ttournament\t1\tGoing around everybody names a Disney movie. Who can't or repeat something, drinks 3 sips. $1 starts. \t\t\t\t\t\tSilvester 2022\t\t\t
333\ttournament\t3\tGoing around everybody names a New Year's traditition. Who can't or repeat something, drinks 3 sips. $1 starts. \t\t\t\t\t\tSilvester 2022\t\t\t
334\ttournament\t6\tBirthday time: Write down the birthdays of the others. Who knows most wins. You have 30 seconds. \t\t\t\t\t\tSilvester 2022\t\t\t
335\ttask\t8\tWho are you, again? $1, tell us the full names of the others. Drink one sip of every name you don't know. \t\t\t\t\t\tSilvester 2022\t\t\t
336\ttournament\t4\tEveryone choose a different animal and imitate its sound. The last one to do so drinks.\t\t\t\t\t\tTime To Get Crazy\t\t\t
337\ttournament\t1\tEach player has to say a word in alphabetical order. If someone messes up, they drink.\t\t\t\t\t\tLet's Drink\t\t\t
338\ttournament\t5\tEveryone takes turns saying a word to create a story. If someone can't think of a word, they drink.\t\t\t\t\t\tTime To Get Crazy\t\t\t
339\ttournament\t5\tEveryone takes turns saying a word to create a story. If someone can't think of a word, they drink.\t\t\t\t\t\tLet's Drink\t\t\t
340\ttournament\t5\tEveryone takes turns saying a word to create an erotic story. If someone can't think of a word, they take of a piece of clothing.\t\t\t\t\t\tXXX\t\t\t
341\ttask\t3\t$1 tell a joke. If no one laughs, $1 drinks 2 sips\t\t\t\t\t\tTime To Get Crazy\t\t\t
342\trule\t3\tRed carpet: Everyone picks a famous person. You are now that person and everyone should act accordingly.\t\tYou are no longer famous, back to your "normal" crazy selfes.\t\t\t\tTime To Get Crazy\t\t\t
343\ttournament\t6\t$1 pick a toungue twister. Going around everone has to repeat it, drink a sip for every mistake you make.\t\t\t\t\t\tTime To Get Crazy\t\t\t
344\ttournament\t7\t$1 and $2 pick a movie scene and act it out without talking. Who guesses the movie can give 3 sips. If no one guesses it, drink 4 sips.\t\t\t\t\t\tLet's Drink\t\t\t
345\ttournament\t4\tEveryone has to come up with a different dance move. If someone repeats a move, they drink.\t\t\t\t\t\tLet's Drink\t\t\t
346\ttask\t5\t$1 do your secet handshake with $2. Don't have one? Make one up! If it looks legit, give 2 sips, otherwise both of you drink 2 sips.\t\t\t\t\t\tLet's Drink\t\t\t
347\ttask\t8\tEveryone, tell $1 a compliment.\t\t\t\t\t\tLet's Drink\t\t\t
348\ttask\t6\t$1 show us some crazy dance moves!\tBetter drink 5 sips to loosen up.\t\t\t\t\tLet's Drink\t\t\t
349\tneverHaveIEver\t2\tNever have I ever been on a blind date.\t\t\t\t\t\tLet's Drink\t\t\t
350\tneverHaveIEver\t2\tNever have I ever gone vegan.\t\t\t\t\t\tLet's Drink\t\t\t
351\tneverHaveIEver\t2\tNever have I ever ridden a horse.\t\t\t\t\t\tLet's Drink\t\t\t
352\tneverHaveIEver\t2\tNever have I ever surfed a wave.\t\t\t\t\t\tLet's Drink\t\t\t
353\tneverHaveIEver\t2\tNever have I ever ridden a motorcycle.\t\t\t\t\t\tLet's Drink\t\t\t
354\trule\t9\tTruth serum: Whenever asks a question, you have to answer truthfully or drink 1 sip.\tNo more truth serum: You all can go back to lying your asses off! \t\t\t\t\tTime To Get Crazy\t\t\t
355\trule\t8\tQuestion Master: Whoever answers a question from $1 drinks!\t$1 is no longer the question master.\t\t\t\t\tLet's Drink\t\t\t
`;
